<template>
  <b-row>
    <b-col cols="12">
      <company-list-view />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CompanyListView from './CompanyListView.vue'

export default {
  components: {
    BRow,
    BCol,
    CompanyListView,
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body {
    background-color: #f8f8f8 !important;
}
</style>
